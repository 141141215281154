import React  from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

export default () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact Us | Joe Little Foundation</title>
      </Helmet>
      <main className="max-w-4xl flex-grow px-6 lg:px-0 md:mx-auto flex flex-col">
        <div className="text-green-700">Your message has been sent!</div>
      </main>
    </Layout>
  )
}
